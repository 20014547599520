module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"/home/runner/work/www.antoinelacour.com/www.antoinelacour.com/node_modules/gatsby-remark-images","id":"26dc12d8-aac5-5e0d-a379-80d5cdabcb97","name":"gatsby-remark-images","version":"6.9.1","modulePath":"/home/runner/work/www.antoinelacour.com/www.antoinelacour.com/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":1200,"sizeByPixelDensity":true,"linkImagesToOriginal":false},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/www.antoinelacour.com/www.antoinelacour.com"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200,"sizeByPixelDensity":true,"linkImagesToOriginal":false},
    }]
